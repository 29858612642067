module.exports = {
  // App Info
  appstore_link: "https://apps.apple.com/us/app/psych-logger/id1592004219", // Enter App Store URL.
  playstore_link:
    "https://play.google.com/store/apps/details?id=org.wehrenterprises.psychlogger", // Enter Google Play Store URL.
  google_analytics_ID: "UA-200742112-1", // Enter Google Analytics ID or ""
  presskit_download_link: null, // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: "video", // "screenshot" or "video"
  app_url: "https://www.psychlogger.com", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "Psych Logger",
  app_price: "$4.99 a month",
  compare_price: "Compare at $89.95/year",
  compare_text: "$59.88/year",
  app_description:
    "Hours Tracking + Automation for Supervised Licensure in Mental & Behavioral Health",
  app_keywords: ["trackyourhours.com", "mental health", "psychologist", "social work", "supervised hours", "supervision", "lmft", "psychology"],

  // Personal Info
  your_name: "a LMFT and a couple nerds",
  your_link: "https://www.psychlogger.com",
  your_city: "San Diego 🇺🇸",
  email_address: "hello@psychlogger.com",
  linkedin_username: null,
  facebook_username: null,
  instagram_username: null,
  twitter_username: null,
  github_username: null,
  youtube_username: null,

  // Features List
  features: [
    {
      title: "Mobile First",
      description: [
        "Optimized for your mobile device for rapid hours tracking.",
      ],
      fontawesome_icon_name: "mobile",
    },
    {
      title: "Visualization",
      description: [
        "View progress rings per category and see calendar insights on days with experience or alert. Receive periodic status emails.",
      ],
      fontawesome_icon_name: "magic",
    },
    {
      title: "License Types",
      description: [
          "· California LCSW",
          "· California LMFT Associate",
          "· California LMFT Trainee",
          "· California LPCC",
          "· California Psychologist",
          "· Florida LCSW",
          "· Florida LMFT",
          "· Florida LMHC",
          "· New York LCSW Post-Grad",
          "· Washington LASW",
          "· Washington LICSW",
          "· Washington MFT",
      ],
      fontawesome_icon_name: "graduation-cap",
    },
    {
      title: "Alerts",
      description: [
        "Get notified of licence type alerts such as ratio violations, or maximum hours worked per week with push notificatoins & app banners.",
      ],
      fontawesome_icon_name: "star",
    },
    {
      title: "Import Your Progress",
      description: [
        "Import all your current experience hours with simple data slurping from trackyourhours.com or a fillable Excel file.",
      ],
      fontawesome_icon_name: "sync",
    },
    {
      title: "Export to PDF",
      description: [
        "With filled out State forms for your license type! One set of PDFs per supervisor/site that you use.",
      ],
      fontawesome_icon_name: "file-pdf",
    },
  ],
  header_background: "rgba(0, 0, 0, 0.1)",
  topbar_title_color: "#A1DFC0",
  cover_overlay_color_rgba: "rgba(54, 59, 61, 0.8)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `Inter, "Helvetica Neue", sans-serif`,
  link_color: "#A1DFC0",
  app_title_color: "#A1DFC0",
  app_price_color: "#ffffff",
  app_description_color: "#EAE784",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#68845D",
  feature_icons_background_color: "#D9EDDF",
  social_icons_foreground_color: "#666666",
  social_icons_background_color: "#e6e6e6",
  footer_text_color: "#666666",
}
